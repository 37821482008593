import { useState, useEffect } from 'react';

const useScreenBreakdown = (breakpoints) => {
    const [screenSize, setScreenSize] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setScreenSize(window.innerWidth);

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const getCurrentBreakpoint = () => {
        for (let i = breakpoints.length - 1; i >= 0; i--) {
            if (screenSize >= breakpoints[i].width) {
                return breakpoints[i];
            }
        }
        return null;
    };

    const currentBreakpoint = getCurrentBreakpoint();

    return { screenSize, currentBreakpoint };
};

export default useScreenBreakdown;