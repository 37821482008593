import { BsStar, BsStarFill, BsStarHalf } from "react-icons/bs";

export const getCountryIcon = (code = "in") => {
    return `https://flagcdn.com/${code}.svg`
}

export const generateProfileName = (name) => {
    let profileName = "";
    const nameArray = name.split(" ");
    if (nameArray.length >= 2) {
        profileName = nameArray[0].charAt(0) + nameArray[1].charAt(0);
    } else if (nameArray.length === 1) {
        profileName = nameArray[0].charAt(0) + nameArray[0].charAt(1);
    }
    return profileName.toUpperCase();
}


