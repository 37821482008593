import React from "react";
import cls from "classnames";
import style from "./style.module.scss";

const ServiceBox = ({ icon, title = "", subtitle = "", color = "blue" }) => {
  return (
    <>
      <div className={cls(style["service-box"], style[`box-${color}`])}>
        <div className={style["icon"]}>{icon}</div>
        <h3>{title}</h3>
        <p className="m-0" dangerouslySetInnerHTML={{ __html: subtitle }}></p>
      </div>
    </>
  );
};

export default ServiceBox;
