import React, { useEffect } from "react";
import Logo from "../../assets/images/logo.png";
import { BiMenu } from "react-icons/bi";
import "./header.scss";
import { Container, Nav, Navbar } from "react-bootstrap";

const Header = () => {
  useEffect(() => {
    if (window !== undefined) {
      let selectHeader = document.querySelector("#header");
      if (selectHeader) {
        const headerScrolled = () => {
          if (window.scrollY > 80) {
            selectHeader.classList.add("header-scrolled");
          } else {
            selectHeader.classList.remove("header-scrolled");
          }
        };
        window.addEventListener("load", headerScrolled);
        document.addEventListener("scroll", headerScrolled);
      }

      let navbarlinks = select("#navbar .scrollto", true);
      const navbarlinksActive = () => {
        let position = window.scrollY + 200;
        navbarlinks.forEach((navbarlink) => {
          if (!navbarlink.hash) return;
          let section = select(navbarlink.hash);
          if (!section) return;
          if (
            position >= section.offsetTop &&
            position <= section.offsetTop + section.offsetHeight
          ) {
            navbarlink.classList.add("active");
          } else {
            navbarlink.classList.remove("active");
          }
        });
      };
      window.addEventListener("load", navbarlinksActive);
      window.addEventListener("scroll", navbarlinksActive);
    }
  }, []);

  const select = (el, all = false) => {
    el = el.trim();
    if (all) {
      return [...document.querySelectorAll(el)];
    } else {
      return document.querySelector(el);
    }
  };

  return (
    <header id="header" className="header fixed-top">
      <Navbar id="navbar" expand="lg" style={{ background: "transparent" }}>
        <Container>
          <Navbar.Brand href="#hero" style={{ padding: "10px 0" }}>
            <img
              src={Logo}
              alt="growwork-infotech-logo"
              style={{ maxHeight: "40px" }}
            />
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            style={{ padding: 0, border: 0, boxShadow: "none" }}
          >
            <BiMenu fontSize={35} fill="#012970" />
          </Navbar.Toggle>
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end"
            style={{ background: "transparent" }}
          >
            <Nav className="ml-auto">
              <Nav.Link href="#services" className="scrollto">
                Services
              </Nav.Link>
              <Nav.Link href="#technology" className="scrollto">
                Technology
              </Nav.Link>
              <Nav.Link href="#ourapproch" className="scrollto">
                Our Approch
              </Nav.Link>
              <Nav.Link href="#contact" className="scrollto">
                Contact
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {/* <div className="container-fluid container-xl d-flex align-items-center justify-content-between">
        <a href="index.html" className="logo d-flex align-items-center">
          <img src={Logo} alt="growwork-infotech-logo" />
        </a>
        <nav id="navbar" className="navbar">
          <ul>
            <li>
              <a className="nav-link scrollto active" href="#hero">
                Home
              </a>
            </li>
            <li>
              <a className="nav-link scrollto" href="#services">
                Services
              </a>
            </li>
            <li>
              <a className="nav-link scrollto" href="#technology">
                Technology
              </a>
            </li>
            <li>
              <a className="nav-link scrollto" href="#ourapproch">
                Our Approch
              </a>
            </li>
            <li>
              <a className="nav-link scrollto" href="#contact">
                Contact
              </a>
            </li>
          </ul>
          <i className="mobile-nav-toggle">
            <BiMenu fontSize={35} fill="#012970" />
          </i>
        </nav>
      </div> */}
    </header>
  );
};

export default Header;
