import React from "react";
import { PRIMARY_COLOR, SECONDARY_COLOR } from "../../config/constant";
import style from "./style.module.scss";
import { RiCodeSSlashFill } from "react-icons/ri";
import { ReactComponent as MobileSVG } from "../../assets/icons/mobile-code.svg";
import { ReactComponent as SoftwareSVG } from "../../assets/icons/software-code.svg";
import { ReactComponent as DigitalSVG } from "../../assets/icons/digital-code.svg";
import { ReactComponent as UIUXSVG } from "../../assets/icons/uiux-code.svg";
import { ReactComponent as DevOps } from "../../assets/icons/devops-code.svg";
import ServiceBox from "./ServiceBox";

const OurServices = () => {
  return (
    <>
      <section id="services" className={style["values"]}>
        <div className="container" data-aos="fade-up">
          <header className="section-header">
            <p>
              Our <span style={{ color: PRIMARY_COLOR }}>Services</span>
            </p>
            <h2
              className={style["section_sub_title"]}
              style={{ color: SECONDARY_COLOR, marginTop: "15px" }}
            >
              We pride ourselves on building successful, end to end <br /> web
              and mobile applications.
            </h2>
          </header>
          <div className="row">
            <div
              className="col-md-6 col-lg-4 mb-4"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <ServiceBox
                icon={<RiCodeSSlashFill className="icon" fontSize={60} />}
                title="Web Development"
                subtitle={`We build beautiful, responsive and feature-rich web
                based applications that solve real customer problems`}
              />
            </div>
            <div
              className="col-md-6 col-lg-4 mb-4"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <ServiceBox
                color="orange"
                icon={<MobileSVG />}
                title="Mobile Application"
                subtitle={`We build Android, iOS and PWA's that work great across multiple devices, screen sizes and resolutions.`}
              />
            </div>
            <div
              className="col-md-6 col-lg-4 mb-4"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <ServiceBox
                color="green"
                icon={<SoftwareSVG />}
                title="Software Development"
                subtitle={`We build custom software for power your business`}
              />
            </div>
            <div
              className="col-md-6 col-lg-4 mb-4"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <ServiceBox
                color="red"
                icon={<DigitalSVG />}
                title="Digital Marketing"
                subtitle={`We degitalize your brand and your brand is the single most important investment you can make in your business. If you're not a brand, you're a commodity.`}
              />
            </div>
            <div
              className="col-md-6 col-lg-4 mb-4"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <ServiceBox
                color="purple"
                icon={<UIUXSVG />}
                title="UI/UX Design"
                subtitle={`We build UI/UX design, In today’s customer-centric world, user experience is everything. At Growwork, we have a experiance team of UI/UX designers with an innovative mindset.`}
              />
            </div>
            <div
              className="col-md-6 col-lg-4 mb-4"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <ServiceBox
                color="pink"
                icon={<DevOps />}
                title="Devops"
                subtitle={`We build beautiful, responsive and feature-rich web
                based applications that solve real customer problems`}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OurServices;
