import axios from "axios";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../helper/firebase";
import moment from "moment";

export const contactUSAPI = async (data) => {
  try {
    // let resp = await axios.post(SERVER_URL + "/contact-us", data);
    let getLocation = await axios.get(
      "https://api.ipregistry.co/?key=7ztyfpzvf48ixgq6"
    );
    let location = getLocation?.data;
    let myData = {
      ...data,
      isDeleted: false,
      createdAt: moment().toISOString(),
      location: location,
    };

    let resp = await addDoc(collection(db, "contactUs"), myData);

    return {
      data: resp,
      error: false,
    };
  } catch (err) {

    return {
      data: null,
      error: err,
      message: "Someting went wrong, please try again later",
    };
  }
};

export const NewsLatterAPI = async (email) => {
  try {
    let getLocation = await axios.get(
      "https://api.ipregistry.co/?key=7ztyfpzvf48ixgq6"
    );
    let location = getLocation?.data;
    let myData = {
      email,
      isDeleted: false,
      createdAt: moment().toISOString(),
      location: location,
    };

    let resp = await addDoc(collection(db, "newsletter"), myData);

    return {
      data: resp,
      error: false,
    };
  } catch (err) {
    return {
      data: null,
      error: err,
      message: "Someting went wrong, please try again later",
    };
  }
};
