import React from "react";
import Contectus from "../../components/Contactus/Contectus";
import LandingMain from "../../components/LandingMain/LandingMain";
import OurApproch from "../../components/OurApproch/OurApproch";
import OurServices from "../../components/OurServices/OurServices";
import OurTechnology from "../../components/OurTechnology/OurTechnology";
import ClientReviews from "../../components/ClientReviews/ClientReviews";
import MeetOursClients from "../../components/MeetOursClients/MeetOursClients";
import "./home.scss";

const Home = () => {
  return (
    <>
      <LandingMain />
      <OurServices />
      {/* <MeetOursClients /> */}
      <OurTechnology />
      <OurApproch />
      <ClientReviews />
      <Contectus />
    </>
  );
};

export default Home;
