import React, { useEffect, useRef, useState } from "react";
import { PRIMARY_COLOR } from "../../config/constant";
import { contactUSAPI } from "../../service/contactus";
import "./style.scss";
import ReCAPTCHA from "react-google-recaptcha";
import { Form } from "react-bootstrap";
import { SITE_KEY as siteKey } from './../../config/constant';

const Contectus = () => {

  const reCaptchaRef = useRef();
  const [loading, setLoading] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [type, setType] = useState("success");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [subjectError, setSubjectError] = useState(false);
  const [messageError, setMessageError] = useState(false);
  const [verified, setVerified] = useState(false)
  const [errorMessage, setErrorMessage] = useState(
    "Someting went wrong, Please try again leter."
  );

  useEffect(() => {
    if (showNotification) {
      setTimeout(() => {
        setShowNotification(false);
      }, 7000);
    }
  }, [showNotification]);

  const handleSend = async () => {

    setLoading(true);
    if (name === "" || subject === "" || email === "" || message === "") {
      setType("error");
      setErrorMessage("All fields are required");
      setShowNotification(true);
      setLoading(false);
      return;
    }

    let reqData = {
      name,
      subject,
      email,
      message,
    };

    let resp = await contactUSAPI(reqData);
    if (resp.error) {
      setType("error");
      setShowNotification(true);
      setErrorMessage("Someting went wrong, Please try again leter.");
    } else {
      setType("success");
      setShowNotification(true);
      setEmail("");
      setName("");
      setSubject("");
      setMessage("");
      reCaptchaRef.current.reset();
    }
    setLoading(false);
  };

  const onSubmit = async () => {
    setShowNotification(false);
    const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    const matched = regex.test(email)
    if (name?.length < 2) setNameError(true)
    if (matched !== true || email.length < 2) setEmailError(true)
    if (subject?.length < 2) setSubjectError(true)
    if (message?.length < 2) setMessageError(true)
    if (
      nameError == false &&
      emailError == false &&
      subjectError == false &&
      messageError == false &&
      matched == true) {
      handleSend()
    }
  }

  const onHandleChange = (verify) => {
    if (verify?.length > 0 || verify === true)
      setVerified(true)
  }
  return (
    <>
      <section id="contact" className="contact">
        <div className="container" data-aos="fade-up">
          <header className="section-header">
            <p>
              Contact <span style={{ color: PRIMARY_COLOR }}>Us</span>
            </p>
          </header>
          <div className="row gy-4">
            <div className="col-lg-6">
              <div className="row gy-4">
                <div className="col-md-6">
                  <div className="info-box">
                    <i className="bi bi-geo-alt"></i>
                    <h3>Address</h3>
                    <p>
                      212, Western Arena, Near madhuvan Circle, L.P.Savani road,
                      adajan, Surat - 395009
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="info-box">
                    <i className="bi bi-telephone"></i>
                    <h3>Call Us</h3>
                    <p>+91 98256 00441</p>
                    <p>+91 90335 71697</p>
                    <br />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="info-box">
                    <i className="bi bi-envelope"></i>
                    <h3>Email Us</h3>
                    <p>info@growworkinfotech.com</p>
                    <p>contact@growworkinfotech.com</p>
                    <p>support@growworkinfotech.com</p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="info-box">
                    <i className="bi bi-clock"></i>
                    <h3>Open Hours</h3>
                    <p>
                      Monday - Friday
                      <br />
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="php-email-form">
                <div className="row gy-4">
                  <div className="col-md-6">
                    <Form.Group >
                      <Form.Control
                        type="text"
                        value={name}
                        onChange={(e) => {
                          setName(e.target.value)
                          if (!name.length < 2) setNameError(false)
                        }}
                        placeholder="Name"
                        isInvalid={nameError}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide name
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className="col-md-6 ">
                    <Form.Group >
                      <Form.Control
                        type="email"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value)
                          if (!email.length < 2) setEmailError(false)
                        }}
                        placeholder="Email"
                        isInvalid={emailError}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide valid email
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>

                  <div className="col-md-12">
                    <Form.Group >
                      <Form.Control
                        type="text"
                        value={subject}
                        onChange={(e) => {
                          setSubject(e.target.value)
                          if (!subject.length < 2) setSubjectError(false)
                        }}
                        placeholder="Subject"
                        isInvalid={subjectError}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide subject
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>

                  <div className="col-md-12">
                    <Form.Group >
                      <Form.Control
                        type="textarea"
                        value={message}
                        onChange={(e) => {
                          setMessage(e.target.value)
                          if (!message.length < 2) setMessageError(false)
                        }}
                        style={{ height: "80px" }}
                        placeholder="Message"
                        isInvalid={messageError}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide message
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>

                  <ReCAPTCHA
                    ref={reCaptchaRef}
                    sitekey={siteKey}
                    onChange={onHandleChange}
                  />
                  <div className="col-md-12 text-center">
                    <div
                      className="loading"
                      style={{ display: loading ? "block" : "none" }}
                    >
                      Loading
                    </div>
                    <div
                      className="error-message"
                      style={{
                        display:
                          loading && showNotification && type === "error"
                            ? "block"
                            : "none",
                      }}
                    >
                      Someting went wrong, Please try again leter.
                    </div>
                    <div
                      className="sent-message"
                      data-aos="flip-up"
                      data-aos-delay="600"
                      style={{
                        display:
                          showNotification && type === "success"
                            ? "block "
                            : "none",
                      }}
                    >
                      Your message has been sent. Thank you!
                    </div>
                    <button
                      type="submit"
                      disabled={!verified}
                      style={{ display: loading ? "none" : "", marginTop: "20px" }}
                      onClick={onSubmit}
                    >
                      Send Message
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contectus;
