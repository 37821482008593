import React from "react";
import ReactSlider from "react-slick";
import "./Slider.scss";

const Slider = ({ items = [] }) => {
  var settings = {
    dots: false,
    infinite: true,
    arrows: false,
    autoplay: true,
    swipeToSlide: true,
    speed: 500,
    slidesToShow: items.length > 4 ? 4 : items.length,
    slidesToScroll: 1,
    initialSlide: 0,

    centerMode: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
          arrows: false,
          autoplay: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div>
      <ReactSlider {...settings}>
        {items.map((item, i) => (
          <div className="slider-wrapper" key={i}>
            <div className="slider-item">
              <img
                src={item}
                className="img-fluid"
                style={{ height: "100px" }}
                alt=""
              />
            </div>
          </div>
        ))}
      </ReactSlider>
    </div>
  );
};

export default Slider;
