import React from 'react'
import { BsStarFill, BsStarHalf } from 'react-icons/bs'
import Img1 from '../../assets/images/testimonials/testimonials-1.jpg'
import { generateProfileName, getCountryIcon } from '../../helper/helper'
import { FaQuoteLeft, FaQuoteRight } from 'react-icons/fa'

const ReviewCard = ({ review = 5, reviewComment = "", name = "", designation = "", country = "", userIcon = "" }) => {
    const wholeStars = Math.floor(review);
    const hasHalfStar = review % 1 !== 0;
    const rating = [1, 2, 3, 4, 5]
    return (
        <>
            <div className="testimonial-item">
                <div className="stars">
                    {rating.map((i) => {
                        if (i <= wholeStars) {
                            return <BsStarFill color="#ffc107" />
                        } else if (hasHalfStar && (i + 1) === wholeStars + 1) {
                            return <BsStarHalf color="#ffc107" /> // add a half star
                        } else {
                            return <BsStarHalf color="#ffc107" />
                        }
                    })}
                </div>
                <p>
                    <span style={{ marginRight: "10px" }}><FaQuoteRight color='rgb(0, 111, 224)' style={{ transform: 'scaleX(-1)' }} /></span>
                    {reviewComment}
                    <span style={{ marginLeft: "10px" }}><FaQuoteRight color='rgb(0, 111, 224)' /></span>
                </p>
                <div className="profile ">
                    {userIcon ? <img src={userIcon} className="testimonial-img" alt="" /> :
                        <div className='profile-pic'>{generateProfileName(name)}</div>}
                    {country && <img src={getCountryIcon(country)} alt="" height={"20px"} width={"20px"} style={{ marginTop: "20px" }} />}
                    <h3 style={{ marginTop: "10px" }}>{name}</h3>
                    {designation && <h4>{designation}</h4>}
                </div>
            </div>
        </>
    )
}

export default ReviewCard
