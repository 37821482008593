import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { PRIMARY_COLOR } from '../../config/constant';
import { A11y, Autoplay, Navigation, Pagination, Scrollbar } from 'swiper';
import Img1 from '../../assets/images/testimonials/testimonials-1.jpg'
import { BsStarFill } from 'react-icons/bs'

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import './style.scss';
import ReviewCard from './ReviewCard';
import { collection, doc, onSnapshot, query } from 'firebase/firestore';
import { db } from '../../helper/firebase';
import useScreenBreakdown from '../../helper/useScreenBreakdown';

const ClientReviews = () => {
    const [reviewLists, setReviewLists] = useState([]);
    const { screenSize } = useScreenBreakdown([]);
    console.info('----------------------------');
    console.info('screenSize =>', screenSize);
    console.info('----------------------------');

    useEffect(() => {
        (async () => {
            const q = query(collection(db, 'clientfeedback'));
            onSnapshot(q, (snapshot) => {
                let allReviews = [];
                snapshot.docs.forEach((item) => {
                    allReviews.push(item?.data())
                });
                setReviewLists(allReviews);

            });
        })();
    }, [])


    return (
        <>
            {reviewLists.length ? <section id="testimonials" className="testimonials">
                <div className="container" data-aos="fade-up">
                    <header className="section-header">
                        <p>
                            What they are  <span style={{ color: PRIMARY_COLOR }}>saying</span> about us
                        </p>
                    </header>
                    <Swiper
                        className='testimonials-slider swiper'
                        data-aos="fade-up"
                        data-aos-delay="200"
                        loop={true}
                        autoplay={{ delay: 3000, pauseOnMouseEnter: true }}
                        modules={[Autoplay, Pagination]}
                        spaceBetween={50}
                        slidesPerView={screenSize < 1200 ? 1 : 3}
                        parallax={true}
                        resizeObserver={true}
                        pagination
                    >

                        {reviewLists?.map((item, i) => <SwiperSlide> <ReviewCard country={item?.country} name={item?.clientName} review={item?.review} reviewComment={item?.feedback} userIcon='' designation={item?.designation} /></SwiperSlide>)}

                    </Swiper>
                </div>
            </section> : null}
            <section>
                <div style={{ display: 'flex', justifyContent: 'center' }}><a target="_blank" href="https://www.goodfirms.co/company/growwork-infotech">
                    <img style={{ width: "243px" }} src="https://assets.goodfirms.co/badges/color-badge/top-website-development-companies.svg" title="Company" alt=" Company" />
                </a></div>
            </section>
        </>
    )
}

export default ClientReviews
